require("babel-polyfill");

// Sticky play with sound button

const banner = document.querySelector(".feature");
const bannerToggle = document.querySelector(".feature__toggle");
const bannerHeight = banner.offsetTop + 100;
const video = document.querySelector(".feature__video");
const toggleText = document.querySelector(".feature__toggleText");
const toggleIconPlay = document.querySelector(".feature__svgPlay");
const toggleIconMute = document.querySelector(".feature__svgMute");

// class names
const myScrollFunc = function () {
	const y = window.scrollY;
	if (bannerHeight <= y) {
		bannerToggle.className = "feature__toggle hide";
	} else {
		bannerToggle.className = "feature__toggle";
	}
};

bannerToggle.addEventListener('click', function () {
	bannerToggle.blur();
	toggleIconPlay.classList.toggle("hide");
	toggleIconMute.classList.toggle("hide");
	// if (toggleText.innerHTML === "Watch our animation with sound") {
	// 	toggleText.innerHTML = "Watch our animation without sound";
	// } else if (toggleText.innerHTML === "Watch our animation without sound") {
	// 	toggleText.innerHTML = "Watch our animation with sound";
	// }
	video.muted = !video.muted;
});

// functions
window.addEventListener("scroll", myScrollFunc);

// Popup controls
// $(".feature__toggle").magnificPopup({
// 	type: "iframe",
// 	closeOnBgClick: false,
// });
